import {createRouter, createWebHistory} from 'vue-router';
import store from "@/store";
import factories from "@/factories";

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        redirect: { name: "Home" },
        component: () => import('./views/Dashboard.vue'),
        children: [
            {
                path: '/home',
                name: 'Home',
                component: () => import('./views/menus/Home.vue')
            },
            {
                path: '/submit',
                name: 'Submit Piracy',
                component: () => import('./views/menus/Submit.vue')
            },
            {
                path: '/asset/:assetId',
                name: 'Asset',
                component: () => import('./views/menus/Asset.vue')
            },
            {
                path: '/domains',
                name: 'Domain Reviewing',
                component: () => import('./views/menus/review/Domains.vue')
            },
            {
                path: '/admin/admin',
                name: 'Admin',
                component: () => import('./views/admin/Admin.vue')
            },
            {
                path: '/searches',
                name: 'Search Manager',
                component: () => import('./views/menus/SearchManager.vue')
            },
            {
                path: '/jobs',
                name: 'Standard Jobs',
                component: () => import('./views/jobs/Standard.vue')
            },
            {
                path: '/settings',
                name: 'System Settings',
                component: () => import('./views/settings/SystemSettings.vue')
            },
            {
                path: '/filters',
                name: 'Filter Settings',
                component: () => import('./views/settings/Filters.vue')
            },
            {
                path: '/providers',
                name: 'Content Providers',
                component: () => import('./views/admin/ContentProvider.vue')
            },
            {
                path: '/linkremoval',
                name: 'LinkRemoval',
                component: () => import('./views/menus/review/LinkRemoval.vue')
            },
            {
                path: '/clients',
                name: 'Clients',
                component: () => import('./views/menus/Clients.vue')
            },
            {
                path: '/scheduled',
                name: 'Scheduled Jobs',
                component: () => import('./views/menus/ScheduledJobs.vue')
            },
            {
                path: '/cyberlockers',
                name: 'Cyberlockers',
                component: () => import('./views/jobs/Cyberlockers.vue')
            },
            {
                path: '/manual',
                name: 'Manual Jobs',
                component: () => import('./views/jobs/Manual.vue')
            },
            {
                path: '/hashes',
                name: 'Torrent Hashes',
                component: () => import('./views/menus/review/TorrentHashes.vue')
            },
            {
                path: '/admin/analytics',
                name: 'Admin Analytics',
                component: () => import('./views/admin/AdminAnalytics.vue')
            },
            {
                path: '/analytics',
                name: 'Analytics',
                component: () => import('./views/menus/Analytics.vue')
            },
            {
                path: '/filter/:searchId',
                name: 'Dynamic Filtering',
                component: () => import('./views/menus/DynamicFiltering.vue')
            },
            {
                path: '/preinject/ingest',
                name: 'Preinject Ingest',
                component: () => import('./views/menus/review/PreinjectIngest.vue')
            },
            {
                path: '/prospects',
                name: 'Prospects',
                component: () => import('./views/menus/Prospects.vue')
            },
            {
                path: '/cases',
                name: 'Case Viewer',
                component: () => import('./views/cases/CaseManager.vue')
            },
            {
                path: '/priorities',
                name: 'Priorities',
                component: () => import('./views/menus/Priorities.vue')
            },
            {
                path: '/newblog',
                name: 'New Blog',
                component: () => import('./views/blogs/NewBlog.vue')
            },
            {
                path: '/blogmanager',
                name: 'Blog Manager',
                component: () => import('./views/blogs/BlogManager.vue')
            },
            {
                path: '/domainlookup',
                name: 'Domain Lookup',
                component: () => import('./views/admin/DomainLookup.vue')
            },
            {
                path: '/assetviewer/:clientName?',
                name: 'Asset Viewer',
                component: () => import('./views/menus/AssetViewer.vue')
            },

            {
                path: '/:catchAll(.*)*',
                name: "PageNotFound",
                component: () => import('./views/PageNotFound.vue'),
            },
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('./views/authentication/Login.vue')
    },
    {
        path: '/cookies',
        name: 'Cookies',
        component: () => import('./views/legal/CookiePolicy.vue')
    },

];

const router = createRouter({
    history: createWebHistory(),
    routes
});

const ignored = ["Login", "Privacy", "Cookies"]
const adminOnly = [
    "Domain Reviewing", "Preinject", "Admin", "Search Manager", "Standard Jobs", "System Settings", "Scheduled Jobs", "Torrent Hashes", "Dynamic Filtering", "Preinject Ingest", "Prospects",
    "Filter Settings", "Content Providers", "LinkRemoval", "Clients", "Cyberlockers", "Admin Analytics", "Manual Jobs", "Case Viewer", "Priorities", "Blog Manager", "Domain Lookup"
]

async function loginWithToken(token) {
    try {
        return factories.auth.loginToken(token)
            .then(response => {
                store.commit("setLoggedIn", response['XSRF-TOKEN']);
                return factories.auth.getUserInfo()
                    .then(response => {
                        store.commit("setUserInfo", response);
                        return true;
                    })
                    .catch(error => {
                        console.error("Error getting user info", error);
                        return false;
                    });
            })
            .catch(error => {
                console.error("Error logging in with token", error);
                return false;
            });
    } catch (error) {
        console.error("Error logging in with token", error);
        return false;
    }
}

async function getAllAssets() {
    return factories.asset.getAllAssets()
        .then(data => {
            store.commit("setAssets", data);
            return true;
        })
        .catch(error => {
            console.error("Error getting all assets", error);
            throw error;
        });
}

async function prepEssentialData() {
    console.debug("==== ROUTER PREPPING ESSENTIAL DATA ====");
    return await Promise.all([
        getAllAssets(),
    ])
}

router.beforeEach(async (to, from, next) => {
    store.commit('initialiseStore')

    if (to.query.token !== undefined) {
        console.debug("Token found in query string", to.query.token);
        if (!store.getters.isLoggedIn) {
            try {
                let workingToken = await loginWithToken(to.query.token);
                console.debug("Working token", workingToken, "isLoggedIn", store.getters.isLoggedIn);
                if (workingToken) {
                    await prepEssentialData();
                    next(to.path);
                } else {
                    next({name: 'Login'});
                }
            } catch (error) {
                console.error("Error logging in with token", error);
                next({name: 'Login'});
            }
        }
    }

    if (!store.getters.isLoggedIn && !ignored.includes(to.name)) {  // Redirect to login if not logged in
        next({ name: 'Login' })
    } else if (store.getters.isLoggedIn && to.name === 'Login') { // Redirect to home if already logged in
        next({name: 'Home'})
    } else if (!store.getters.isStaff && adminOnly.includes(to.name)) {  // Redirect to home if not staff and trying to access admin only pages
        next({name: 'Home'})
    } else {  // Continue to the page
        next()
    }
})

export default router;
